
import { defineComponent, PropType } from 'vue'
import { Mode } from '@/modules/mode'
import SelectButton from 'primevue/selectbutton'
import Button from 'primevue/button'
import Legend from '@/components/Legend.vue'

export default defineComponent({
  name: 'GUI',
  components: { Legend, SelectButton, Button },
  emits: [ 'update:mode' ],
  props: {
    mode: {
      type: Number as PropType<Mode>,
      default: Mode.VIEW,
    },
  },
  data() {
    return {
      modes: [
        { mode: Mode.VIEW, icon: 'navigation' },
        { mode: Mode.EDIT_DRAW, icon: 'draw' },
        { mode: Mode.EDIT_LINE, icon: 'linear_scale' }/*,
        { mode: Mode.EDIT_TEXT, icon: 'text_fields' }*/ ],
    }
  },

  methods: {
    onModeChange(nVal: Mode) { this.$emit('update:mode', nVal) },
    undo() { this.$hist.undo() },
    redo() { this.$hist.redo() },
  }
})
