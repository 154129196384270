
import { defineComponent } from 'vue'
import categories, { Category } from '@/modules/data/categories'
import { color } from '@/modules/utils/colors'

export default defineComponent({
  name: 'Legend',
  data() {
    return {
      categories: Object.values(categories),
      opened: false
    }
  },

  methods: {
    colorOf(cat: Category): string { return color(cat.color) },
    blocks(qty: number): string { return '&block;'.repeat(qty) },
  },
})
