
import { defineComponent, Prop } from 'vue'
import { City, ICity } from '@/modules/data/cities'
import { GAME_MAP_SIZE } from '@/modules/utils/constants'
import { IState } from '@/modules/state'
import { color } from '@/modules/utils/colors'
import tippy from 'tippy.js'
import { IBoost } from '@/modules/data/boosts'
import { round } from '@/modules/utils/math'

export function colorOf(city: ICity): string {
  let boosts = city.boosts.filter((b) => !b.cat.disabled)
  if (boosts.length === 0)
    return color(0)
  return color(boosts[0].cat.color)
}

export default defineComponent({
  name: 'KowCity',
  emits: ['city-select'],
  props: {
    city: {
      type: City,
      required: true,
    },
    state: <Prop<IState>>{
      required: true,
    },
  },

  computed: {
    btn(): HTMLButtonElement { return this.$refs.btn as HTMLButtonElement },
    dot(): HTMLElement | null { return this.$refs.dot as HTMLElement | null },
    tooltip(): HTMLElement { return this.$refs.tooltip as HTMLElement }
  },

  mounted(): void {
    this.btn.style.borderColor =
        this.btn.style.color = colorOf(this.city)
    if (this.dot != null)
      this.dot.style.backgroundColor = this.btn.style.color

    let [ x, y ] = this.city.coords
    let rX       = x / GAME_MAP_SIZE * 100,
        rY       = y / GAME_MAP_SIZE * 100

    this.btn.style.left   = `${rX}%`
    this.btn.style.bottom = `${rY}%`

    tippy(this.btn, {
      content: this.tooltip,
      interactive: true,
      appendTo: document.body,
    })
  },

  methods: {
    onClick(ev: PointerEvent): void {
      this.$emit('city-select', this.city, ev)
    },

    fmtBoost(boost: IBoost): string {
      return `${round(boost.value * 100, 2)}%`
    }
  }
})

